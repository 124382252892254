import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './ShippingDelivery.css';

const ShippingDelivery = () => {
  return (
    <div>
      <Header />
      <h1 className="medicoed-shipping-title">Shipping and <span style={{color: "#ff416c"}}>Delivery</span> Policy</h1>
      <div className="medicoed-shipping-container">
        <div className="medicoed-shipping-content">
          <section className="medicoed-shipping-section">
            <h2 className="medicoed-shipping-heading">Digital Delivery Policy</h2>
            <p className="medicoed-shipping-paragraph">
              Since MedicoEd offers digital services, there is no physical shipping involved. Once you have placed an order and received your invoice, your account plan status will be updated immediately to reflect the plan you have purchased.
            </p>
            <p className="medicoed-shipping-paragraph">
              This means that all features and benefits associated with your chosen plan will be accessible right away. Simply refresh your account page after completing the purchase to start using the new features.
            </p>
          </section>
          
          <section className="medicoed-shipping-section">
            <h2 className="medicoed-shipping-heading">Access to Plan Features</h2>
            <p className="medicoed-shipping-paragraph">
              You can enjoy all the features of your new plan immediately after updating your account. This includes access to premium content, enhanced tools, and any other benefits included in your subscription.
            </p>
            <p className="medicoed-shipping-paragraph">
              If you encounter any issues with accessing your plan features, please don't hesitate to contact our support team for assistance.
            </p>
          </section>
          
          <section className="medicoed-shipping-section">
            <h2 className="medicoed-shipping-heading">Additional Information</h2>
            <p className="medicoed-shipping-paragraph">
              Your account plan status will remain updated unless you choose to change or cancel your subscription. We strive to provide seamless and instant access to our services, ensuring you can start benefiting from your new plan right away.
            </p>
            <p className="medicoed-shipping-paragraph">
              Thank you for choosing MedicoEd. If you have any questions or need further assistance, please visit our Contact Us page.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingDelivery;