import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './CancellationRefund.css';

const CancellationRefund = () => {
  return (
    <div>
      <Header />
      <h1 className="medicoed-cancellation-title">Cancellation and <span style={{color: "#ff416c"}}>Refund</span> Policy</h1>
      <div className="medicoed-cancellation-container">
        <div className="medicoed-cancellation-content">
          <section className="medicoed-cancellation-section">
            <h2 className="medicoed-cancellation-heading">Cancellation Policy</h2>
            <p className="medicoed-cancellation-paragraph">
              We understand that circumstances may change, and you might need to cancel your subscription. Please note the following regarding our cancellation policy:
            </p>
            <ul className="medicoed-cancellation-list">
              <li className="medicoed-cancellation-list-item">Once a subscription has been started, any cancellation will apply to the next billing cycle.</li>
              <li className="medicoed-cancellation-list-item">You can continue to use your plan until the end of the current billing period.</li>
              <li className="medicoed-cancellation-list-item">No partial refunds will be issued for unused time in the current billing cycle.</li>
            </ul>
          </section>
          
          <section className="medicoed-cancellation-section">
            <h2 className="medicoed-cancellation-heading">Refund Policy</h2>
            <p className="medicoed-cancellation-paragraph">
              For refund inquiries, please visit our Contact Us page and create an inquiry either by email or phone. Our support team will be happy to assist you with your refund request.
            </p>
            <p className="medicoed-cancellation-paragraph">
              To submit a refund request or for any questions regarding our refund policy, you can:
            </p>
            <ul className="medicoed-cancellation-list">
              <li className="medicoed-cancellation-list-item">Email us at: support@medicoed.com</li>
              <li className="medicoed-cancellation-list-item">Call us at: +7 917 395-01-16</li>
              <li className="medicoed-cancellation-list-item">Use our <Link to="/contact" className="medicoed-cancellation-link">contact form</Link></li>
            </ul>
          </section>
          
          <section className="medicoed-cancellation-section">
            <h2 className="medicoed-cancellation-heading">Additional Information</h2>
            <p className="medicoed-cancellation-paragraph">
              Please note that refund eligibility may vary depending on the circumstances and the specific subscription plan. Our support team will review each request on a case-by-case basis.
            </p>
            <p className="medicoed-cancellation-paragraph">
              We strive to process all cancellation and refund requests as quickly as possible. Thank you for your understanding and cooperation.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancellationRefund;