import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Contact.css';

const Contact = () => {
  return (
    <div>
      <Header />
      <h1 className="medicoed-contact-title">Contact <span style={{color: "#ff416c"}}>Us</span></h1>
      <div className="medicoed-contact-container">
        <div className="medicoed-contact-content">
          <section className="medicoed-contact-section">
            <h2 className="medicoed-contact-heading">Get in Touch</h2>
            <p className="medicoed-contact-paragraph">
              We're here to help! If you have any questions, concerns, or feedback, please don't hesitate to reach out to us using the contact information below.
            </p>
          </section>
          <section className="medicoed-contact-section">
            <h2 className="medicoed-contact-heading">Contact Information</h2>
            <p className="medicoed-contact-paragraph">
              <strong>Email:</strong> support@medicoed.com
            </p>
            <p className="medicoed-contact-paragraph">
              <strong>Phone:</strong> +7 917 395-01-16
            </p>
          </section>
          <section className="medicoed-contact-section">
            <h2 className="medicoed-contact-heading">Contact Form</h2>
            <form className="medicoed-contact-form">
              <div className="medicoed-form-group">
                <label htmlFor="name" className="medicoed-form-label">Name:</label>
                <input type="text" id="name" name="name" className="medicoed-form-input" required />
              </div>
              <div className="medicoed-form-group">
                <label htmlFor="email" className="medicoed-form-label">Email:</label>
                <input type="email" id="email" name="email" className="medicoed-form-input" required />
              </div>
              <div className="medicoed-form-group">
                <label htmlFor="message" className="medicoed-form-label">Message:</label>
                <textarea id="message" name="message" className="medicoed-form-textarea" required></textarea>
              </div>
              <button type="submit" className="medicoed-form-submit">Send Message</button>
            </form>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;